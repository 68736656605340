/** @jsx jsx */
import { graphql } from "gatsby"

import { jsx } from "@trueskin-web/theme"

import SEODetails from "../block-library/components/seo-details"
import Layout from "../components/layout"
import Legal from "../templates/legal"

const TermsAndConditionsPage = ({
  data: {
    strapi: { termsAndConditionsPage },
  },
}) => (
  <Layout title={termsAndConditionsPage.title}>
    <SEODetails
      title={termsAndConditionsPage.title}
      {...termsAndConditionsPage.seoDetails}
    />

    <Legal
      title={termsAndConditionsPage.title}
      subtitle={termsAndConditionsPage.subtitle}
      content={termsAndConditionsPage.content}
      blocks={termsAndConditionsPage.blocks}
    />
  </Layout>
)

export const query = graphql`
  {
    strapi {
      termsAndConditionsPage {
        title
        seoDetails {
          ...SEODetailsFragment
        }
        blocks {
          __typename
          ... on Strapi_ComponentAtomsRichContent {
            ...RichContentFragment
          }
        }
        subtitle
        content
      }
    }
  }
`

export default TermsAndConditionsPage
